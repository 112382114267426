import { insertScriptOnTheDOM } from '@shared/utils/dom';
import { useEffect } from 'react';

const IGNORE_PATHS = ['/radar', '/diagnostico', '/interacao-medicamentosa', '/wb-assist'];

export default function useAddCookieNoticeScript() {
  useEffect(() => {
    const path = window?.location?.pathname;
    const ignore = IGNORE_PATHS.find((entry) => path?.startsWith(entry));
    if (!ignore) addScript();
  });

  const addScript = () => {
    setTimeout(() => {
      const scriptAttributes = {
        src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        id: 'modal-cookie-afya',
        type: 'text/javascript',
        'data-domain-script': process.env.NEXT_PUBLIC_OPTANON_DATA_DOMAIN_SCRIPT,
      };

      insertScriptOnTheDOM(scriptAttributes);

      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
      function OptanonWrapper() {}
    }, 8000);
  };
}
