import { subscribeStepName, subscribeTypeEnum } from '@analytics/snowplow/events/subscribe/types';
import { loginStepNameEnum, loginTypeEnum } from '@analytics/snowplow/events/login/types';
import { signupStepNameEnum, signupTypeEnum } from '@analytics/snowplow/events/signup/types';
import { viewEnum } from '@analytics/snowplow/events/view/types';
import { UserModel } from '@remote-data/sign-in/types';
import { sha256 } from 'js-sha256';
import { listActionEnum, listActionEnumContext } from '@analytics/snowplow/events/list_action/types';
import { feedbackEnum } from '@analytics/snowplow/events/feedback/types';
import { viewTimeEnum } from '@analytics/snowplow/events/view _time/types';
import { SnowplowExperiment, SnowplowTopic } from '@analytics/snowplow/events/types';
import { bookmarkEnum } from '@analytics/snowplow/events/bookmark/types';
import { playMediaEnum, playStateEnum } from '@analytics/snowplow/events/play/types';
import { carouselListActionEnum, carouselListActionEnumContext } from '@analytics/snowplow/events/carousel/types';
import { actionShareEnum, targetShareEnum } from '@analytics/snowplow/events/share/types';
import { ratingTypeEnum, reasonButtonEnum } from '@analytics/snowplow/events/rate/types';
import { signupUpdateEnum } from './signup_update/types';
import { buttonClickEnum } from './button_click/types';
import { methodEnum } from './search/types';
import { popupInteractionEnum, popupValueEnum, typeEnum } from './popup_view/types';
import { sendEntity, sendTrack } from './track';

export const snowplowSignupUpdate = (
  step_name: signupUpdateEnum,
  step_num: number | null,
  step_value: string | null,
  failure_reason: string | null = null,
): void => {
  sendTrack({ step_name, step_num, step_value, failure_reason }, 'signup_update');
};

export const snowplowBookmark = (action: bookmarkEnum = null): void => {
  sendTrack({ action }, 'bookmark', null, undefined, false);
};

export const snowplowButtonClick = (
  button_name: buttonClickEnum,
  button_location: string,
  additional_properties: string | null = null,
  position: string | null = null,
  related_item_ids: string[] | null = null,
  action: string | null = null,
): void => {
  sendTrack(
    { button_name, button_location, additional_properties, position, related_item_ids, action },
    'button_click',
  );
};

export const snowplowPopupView = (
  type: typeEnum,
  popup_interaction: popupInteractionEnum,
  popup_value: popupValueEnum | null,
  failure_reason?: string | null,
  additional_properties?: string | null,
): void => {
  sendTrack({ type, popup_interaction, popup_value, failure_reason, additional_properties }, 'popup_view');
};

export const snowplowSearch = (
  keywords: string | null,
  num_results: number | null,
  content_searched: string | null,
  method: methodEnum,
  failure_reason?: string | null,
  results_categories?: string | null,
  additional_properties?: string | null,
): void => {
  sendTrack(
    { keywords, num_results, content_searched, failure_reason, method, results_categories, additional_properties },
    'search',
  );
};

export const snowplowSubscribe = (
  type: subscribeTypeEnum,
  step_name: subscribeStepName,
  step_num: number,
  plan_id?: number,
  step_value?: string | null,
  failure_reason?: string | null,
): void => {
  sendTrack({ type, step_name, step_num, plan_id, step_value, failure_reason }, 'subscribe');
};

export const snowplowLogin = (
  type: loginTypeEnum,
  step_num: number | null,
  step_name: loginStepNameEnum,
  automatic: boolean,
  failure_reason?: string | null,
  step_value?: string | null,
): void => {
  sendTrack({ type, step_num, step_name, automatic, failure_reason, step_value }, 'login');
};

export const snowplowSignup = (
  type: signupTypeEnum,
  step_name: signupStepNameEnum,
  step_num: number,
  step_value: string | null,
  failure_reason?: string | null,
): void => {
  sendTrack({ type, step_name, step_num, step_value, failure_reason }, 'signup');
};

export const snowplowView = (type: viewEnum, ignoreEntities?: string[]): void => {
  sendTrack({ type }, 'view', null, ignoreEntities);
};

export const snowplowListAction = (
  action_name: listActionEnum,
  context: listActionEnumContext,
  item_id: number | null,
  item_value: string | null,
  item_position: number | null = null,
  related_item_ids: string[] | null = null,
): void => {
  sendTrack({ action_name, context, item_id, item_value, item_position, related_item_ids }, 'list_action');
};

export const snowplowCarouselListAction = (
  action_name: carouselListActionEnum,
  context: carouselListActionEnumContext,
  item_id: number | null = null,
  item_value: string | null = null,
  item_position: number | null = null,
  list_size: number | null = null,
  related_item_ids: string[] | null = null,
): void => {
  sendTrack({ action_name, context, item_id, item_value, item_position, list_size, related_item_ids }, 'list_action');
};

export const snowplowFeedback = (type: feedbackEnum, comment: string, score: number | null = null): void => {
  sendTrack({ type, comment, score }, 'feedback');
};

export const snowplowViewTime = (metric_name: viewTimeEnum, time: number): void => {
  sendTrack({ metric_name, time }, 'view_time');
};

export const snowplowPlay = (
  source: string,
  media: playMediaEnum,
  video_id: string,
  state: playStateEnum,
  creator: string,
  additional_properties?: string | null,
  ignoreEntities?: string[],
): void => {
  sendTrack({ source, media, video_id, state, creator, additional_properties }, 'play', null, ignoreEntities);
};

export const snowplowShare = (
  target: targetShareEnum,
  action: actionShareEnum,
  button_location?: string,
  deep_link_id?: string,
): void => {
  sendTrack({ button_location, target, action, deep_link_id }, 'share');
};

export const snowplowRate = (
  rating: number,
  reason_button: reasonButtonEnum | null,
  rating_type: ratingTypeEnum,
  reason_text: string,
): void => {
  sendTrack({ rating, reason_button, rating_type, reason_text }, 'rate');
};

export const snowplowUser = (user: UserModel): void => {
  const userData = {
    user_id: user.id,
    user_profile: user.profileId,
    user_email: sha256(user.email),
    user_type_id: user.userTypeId,
    user_medical_formation: user.medicalFormationId,
    user_occupation_area: user.areaExpertiseId,
    user_specialty: user.specialtyId,
    subscription_id: user.subscription.id || null,
    user_formation_degree: user.degreeFormationType || null,
    user_graduation_year: parseInt(user.graduation, 10) || null,
  };
  sendEntity(userData, 'user');
};

export const snowplowCategory = (category: { titulo: string; id: string | number; tipo: number | null }): void => {
  const categoryData = {
    category_title: category.titulo,
    category_id: category.id,
    category_user_type_id: category.tipo,
  };
  sendEntity(categoryData, 'category');
};

export const snowplowTopic = (topic: SnowplowTopic): void => {
  sendEntity(topic, 'topic');
};

export const snowplowExperiment = (experiment: SnowplowExperiment): void => {
  sendEntity(experiment, 'experiment');
};
