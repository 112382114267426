import Head from 'next/head';

export default function ProfileTags() {
  return (
    <Head>
      <link rel="preload" href="/_next/static/css/dfe88e673c6455ff.css" as="style" />
      <link rel="stylesheet" href="/_next/static/css/dfe88e673c6455ff.css" data-n-p="" />
    </Head>
  );
}
