import { ProgressBarActionTypes } from '@context/progress-bar/actions';
import { ProgressBarActionsEnum, ProgressBarState } from '@context/progress-bar/types';

export const initialProgressBarState: ProgressBarState = { percentage: 0 };

export const progressBarReducer = (
  state: ProgressBarState = initialProgressBarState,
  action: ProgressBarActionTypes,
) => {
  switch (action.type) {
    case ProgressBarActionsEnum.SET_PROGRESS_PERCENTAGE:
      return { ...state, percentage: action.percentage };
    default:
      return state;
  }
};
