import { FirstSessionPaywallType } from '@hooks/use-remote-config/types/first-session-paywall';

export const defaultFirstSessionPaywallValues: { web_first_session_paywall: FirstSessionPaywallType } = {
  web_first_session_paywall: {
    data: [
      {
        enabled: false,
        type: '',
        icon: '',
        title: '',
        carousel: {
          initialPosition: 0,
          items: [
            {
              enabled: false,
              name: '',
              title: '',
              description: '',
              img: '',
            },
          ],
        },
        button: {
          text: '',
          icon: '',
        },
        subscription: {
          title: '',
          annualPlanText: '',
          monthlyPlanText: '',
        },
      },
    ],
  },
};
