import { MedfridayType } from '@hooks/use-remote-config/types/medfriday';
import { RecaptchaType } from '@hooks/use-remote-config/types/recaptcha';
import { OfferCommunicationsType } from '@hooks/use-remote-config/types/offer-communications';
import { YellowSeptemberType } from '@hooks/use-remote-config/types/yellow-september';
import { FirstSessionPaywallType } from '@hooks/use-remote-config/types/first-session-paywall';
import { ApprovedExperimentsType } from './approved-experiments';

export type DataTypes = {
  medfriday?: MedfridayType;
  web_offer_communications?: OfferCommunicationsType;
  web_recaptcha?: RecaptchaType;
  web_yellow_september?: YellowSeptemberType;
  web_approved_experiments?: ApprovedExperimentsType;
  web_first_session_paywall?: FirstSessionPaywallType;
};

export const RemoteConfigKeysTypes = [
  'medfriday',
  'web_offer_communications',
  'web_recaptcha',
  'web_approved_experiments',
  'web_yellow_september',
  'web_first_session_paywall',
] as const;

export const RemoteConfigKeys = [...RemoteConfigKeysTypes];

export const isFreeUserExclusive = ['web_offer_communications'];
