import { sentryCaptureException } from '@configs/sentry';
import { ContextConsumer } from '@context/index';
import { RemoteConfigActionsEnum } from '@context/remote-config/types';
import { defaultApprovedExperimentsValues } from '@hooks/use-remote-config/default-values/approved-experiments';
import { defaultFirstSessionPaywallValues } from '@hooks/use-remote-config/default-values/first-session-paywall';
import { defaultOfferCommunicationsValues } from '@hooks/use-remote-config/default-values/offer-communications';
import { defaultRecaptchaValues } from '@hooks/use-remote-config/default-values/recaptcha';
import { yellowSeptemberValues } from '@hooks/use-remote-config/default-values/yellow-september';
import RemoteConfig from '@hooks/use-remote-config/remote-config';
import { DataTypes, RemoteConfigKeysTypes } from '@hooks/use-remote-config/types';
import { useContext, useState } from 'react';

interface HooksReturn {
  loading: boolean;
  initRemoteConfig(): Promise<void>;
  fetchValues(): Promise<void>;
  getCachedValues(key: typeof RemoteConfigKeysTypes[number]): any;
}

export const useRemoteConfig = (): HooksReturn => {
  const [loading, setLoading] = useState(true);
  const { state, dispatch } = useContext(ContextConsumer);

  const initRemoteConfig: HooksReturn['initRemoteConfig'] = async () => {
    try {
      const defaultValuesObj: DataTypes = transformToObject([
        defaultRecaptchaValues,
        defaultOfferCommunicationsValues,
        yellowSeptemberValues,
        defaultApprovedExperimentsValues,
        defaultFirstSessionPaywallValues,
      ]);
      await RemoteConfig.initRemoteConfig(defaultValuesObj);
    } catch (error) {
      if (canSendToSentry(error)) {
        sentryCaptureException(error, {
          tags: {
            section: 'useRemoteConfig',
          },
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const transformToObject = (defaultValues: DataTypes[]) => {
    return defaultValues.reduce((acc, cur) => {
      return { ...acc, ...cur };
    }, {});
  };

  const getCachedValues: HooksReturn['getCachedValues'] = (key) => {
    if (!state?.remoteConfig?.data) return null;
    return { [key]: state?.remoteConfig?.data[key] };
  };

  const fetchValues: HooksReturn['fetchValues'] = async () => {
    try {
      const values: DataTypes = await RemoteConfig.getValuesRemoteConfig();
      dispatch({ type: RemoteConfigActionsEnum.SET_DATA, payload: values });
    } catch (error) {
      if (canSendToSentry(error)) {
        sentryCaptureException(error, {
          tags: {
            section: 'useRemoteConfig',
          },
        });
      }
    }
  };

  const canSendToSentry = (error: any) =>
    error?.code !== 'remoteconfig/fetch-client-network' || error?.code !== 'installations/app-offline';

  return { loading, getCachedValues, initRemoteConfig, fetchValues };
};
