import { initFirebase } from '@configs/firebase';
import { RemoteConfigKeys, isFreeUserExclusive } from '@hooks/use-remote-config/types';
import { getApp } from 'firebase/app';
import { fetchAndActivate, getRemoteConfig, getValue } from 'firebase/remote-config';
import User from '@shared/utils/user';

export default class RemoteConfig {
  static async initRemoteConfig(defaultValues) {
    const app = initFirebase();

    const remoteConfig = getRemoteConfig(app);
    remoteConfig.defaultConfig = defaultValues;

    return remoteConfig;
  }

  static getInstanceRemoteConfig() {
    const app = getApp('wbweb');

    return getRemoteConfig(app);
  }

  static async getValuesRemoteConfig() {
    const instance = RemoteConfig.getInstanceRemoteConfig();
    await fetchAndActivate(instance);
    let keys = RemoteConfigKeys;
    if (User.isUserPremium()) {
      keys = keys.filter((val) => !isFreeUserExclusive.includes(val));
    }

    const result = Object.fromEntries(
      keys.map((remoteKey) => {
        if (getValue(instance, remoteKey).getSource() !== 'remote')
          return [remoteKey, instance.defaultConfig[remoteKey]];
        return [remoteKey, JSON.parse(getValue(instance, remoteKey).asString())];
      }),
    );

    return result;
  }
}
