import { FormActionTypes } from '@context/form/actions';
import { FormActionsEnum } from '@context/form/types';
import RegistrantSignUpModelType from '@models/registrant-sign-up-model';

export const initialFormState: RegistrantSignUpModelType = {
  formationDegree: '',
  name: '',
  birthDate: '',
  nationality: { value: 33, label: 'Brasil' },
  workCountry: { value: 33, label: 'Brasil' },
  uf: { value: 0, label: 'Selecionar' },
  cpf: '',
  cellphone: '',
  allowCommunications: true,
  email: '',
  confirmationEmail: '',
  password: '',
  confirmationPassword: '',
  occupationArea: { value: 0, label: 'Selecionar' },
  graduationYear: { value: 0, label: 'Selecionar' },
  specialty: { value: 0, label: 'Selecionar' },
  medicalFormation: { value: 0, label: 'Selecionar' },
  professionalCouncilNumber: '',
  college: { value: 0, label: 'Selecionar' },
  tokenrecaptcha: '',
};

export const formReducer = (
  state: RegistrantSignUpModelType = initialFormState,
  action: FormActionTypes,
): RegistrantSignUpModelType => {
  switch (action.type) {
    case FormActionsEnum.SET_FORMATION_DEGREE:
      return { ...state, formationDegree: action.formationDegree };

    case FormActionsEnum.SET_NAME:
      return { ...state, name: action.name };

    case FormActionsEnum.SET_BIRTH_DATE:
      return { ...state, birthDate: action.birthDate };

    case FormActionsEnum.SET_NATIONALITY:
      return { ...state, nationality: action.nationality };

    case FormActionsEnum.SET_WORK_COUNTRY:
      return { ...state, workCountry: action.workCountry };

    case FormActionsEnum.SET_UF:
      return { ...state, uf: action.uf };

    case FormActionsEnum.SET_CPF:
      return { ...state, cpf: action.cpf };

    case FormActionsEnum.SET_CELLPHONE:
      return { ...state, cellphone: action.cellphone };

    case FormActionsEnum.SET_ALLOW_COMMUNICATIONS:
      return { ...state, allowCommunications: action.allowCommunications };

    case FormActionsEnum.SET_EMAIL:
      return { ...state, email: action.email };

    case FormActionsEnum.SET_CONFIRMATION_EMAIL:
      return { ...state, confirmationEmail: action.confirmationEmail };

    case FormActionsEnum.SET_PASSWORD:
      return { ...state, password: action.password };

    case FormActionsEnum.SET_CONFIRMATION_PASSWORD:
      return { ...state, confirmationPassword: action.confirmationPassword };

    case FormActionsEnum.SET_OCCUPATION_AREA:
      return { ...state, occupationArea: action.occupationArea };

    case FormActionsEnum.SET_GRADUATION_YEAR:
      return { ...state, graduationYear: action.graduationYear };

    case FormActionsEnum.SET_SPECIALTY:
      return { ...state, specialty: action.specialty };

    case FormActionsEnum.SET_MEDICAL_FORMATION:
      return { ...state, medicalFormation: action.medicalFormation };

    case FormActionsEnum.SET_REGISTER_NUMBER:
      return { ...state, professionalCouncilNumber: action.professionalCouncilNumber };

    case FormActionsEnum.SET_COLLEGE:
      return { ...state, college: action.college };

    case FormActionsEnum.RESET:
      return initialFormState;

    case FormActionsEnum.SET_PROFILE_DATA_EDIT:
      return { ...state, ...action.profileData };

    default:
      return state;
  }
};
