import Head from 'next/head';

export default function LandingTags() {
  return (
    <Head>
      <title>Afya Whitebook Clinical Decision - Aplicativo de Medicina</title>
      <meta
        name="description"
        content="Whitebook: app de médicos para médicos com mais de 9000 conteúdos de diferentes especialidades, trazendo segurança para você tomar a melhor decisão clínica."
      />
      <link rel="preload" fetchpriority="high" as="image" href="/images/bg-home-mobile.webp" type="image/webp" />
    </Head>
  );
}
