export enum PageLayoutTypes {
  FreeContent,
  Login,
  Landing,
  Logged,
  SignUp,
  LpRenewal,
  OnlyChildren,
  Polices,
}

export enum PageHeaderTypes {
  Login,
  Landing,
  ForgotPass,
  FreeContent,
  SignUp,
  Logged,
  Empty,
  DrugInteraction,
  Diagnosis,
  LpRenewal,
  HeaderShared,
  Polices,
  Switch,
  ProgressBar,
}
