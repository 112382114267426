export enum GeneralSearchActionsEnum {
  SEARCH_TERM = '@search/SEARCH_TERM',
  SELECTED_TAB = '@search/SELECTED_TAB',
  OPEN_SEARCH_INPUT = '@search/OPEN_SEARCH_INPUT',
}

export enum GeneralSearchTabs {
  CONTENT = 'content',
  CODE = 'code',
}

export type GeneralSearchState = {
  searchTerm?: string;
  selectedTab?: GeneralSearchTabs;
  openSearchInput?: boolean;
};
