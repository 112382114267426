import GeneralTags from '@head-tags/general-tags';
import CategoryContentTags from '@head-tags/page-tags/CategoryContent';
import FreeContentTags from '@head-tags/page-tags/FreeContent';
import LandingTags from '@head-tags/page-tags/Landing';
import LoginTags from '@head-tags/page-tags/Login';
import ProfileTags from '@head-tags/page-tags/Profile';
import SignUpTags from '@head-tags/page-tags/Signup';
import { PageHeadTagTypes } from '@head-tags/page-tags/types';

const mapHeadTagsToComponent = {
  [PageHeadTagTypes.FreeContent]: FreeContentTags,
  [PageHeadTagTypes.Login]: LoginTags,
  [PageHeadTagTypes.Landing]: LandingTags,
  [PageHeadTagTypes.SignUp]: SignUpTags,
  [PageHeadTagTypes.CategoryContent]: CategoryContentTags,
  [PageHeadTagTypes.Profile]: ProfileTags,
  [PageHeadTagTypes.Empty]: () => {
    return null;
  },
};

export default function HeadTags({ tags }: { tags: string }) {
  const SpecificPageHead = mapHeadTagsToComponent[tags] || mapHeadTagsToComponent[PageHeadTagTypes.Empty];

  return (
    <>
      <GeneralTags />
      <SpecificPageHead />
    </>
  );
}
